@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/swiper-bundle";

// @font-face {
//     font-family: 'Inter';
//     src: url('../fonts/Inter-Regular.woff2') format('woff2'),
//         url('../fonts/Inter-Regular.woff') format('woff');
//     font-weight: normal;
// }

// @font-face {
//     font-family: 'Inter';
//     src: url('../fonts/Inter-Bold.woff2') format('woff2'),
//         url('../fonts/Inter-Bold.woff') format('woff');
//     font-weight: bold;
// }

header nav a[aria-current] {
  @apply font-bold;
}

.navigation {
  .button-prev,
  .button-next {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("../../../assets/icons/arrow-left.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .button-next {
    transform: rotate(180deg);
  }
}
